import React from "react";
import PropTypes from "prop-types";
import ProfileCard from "./ProfileCard";
import Grid from "@material-ui/core/Grid";
import { graphql, StaticQuery } from "gatsby";

const Profiles = props => {
  const { theme } = props;

  return (
    <StaticQuery
      query={graphql`
        query ProfilesQuery {
          profiles: allProfilesJson {
            edges {
              node {
                id
                name
                role
                avatar
                xing
                cv
                infoComponent
              }
            }
          }
        }
      `}
      render={({ profiles: { edges: profiles } }) => (
        <React.Fragment>
          <div className="profile-container">
            <h1>Profile</h1>

            <Grid container justify="center" spacing={32} className={"profiles"}>
              {profiles &&
                profiles.map(({ node: profile }) => (
                  <Grid xs={12} md={6} item key={profile.id}>
                    <ProfileCard profile={profile} theme={theme} />
                  </Grid>
                ))}
            </Grid>
          </div>

          {/*language=SCSS*/}
          <style jsx>{`
            .profile-container :global(.profiles) {
              margin-top: 30px;
            }
          `}</style>
        </React.Fragment>
      )}
    />
  );
};

Profiles.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Profiles;
