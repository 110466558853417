import React, { Fragment } from "react";

const MiyaProfileContent = ({ theme }) => {
  return (
    <Fragment>
      <div>
        <div className="design-stack">
          <h2>Skills</h2>
          <ul className="list">
            <li>User Research</li>
            <li>Usability Testing</li>
            <li>Konzeption und Prototyping</li>
            <li>UI Design</li>
            <li>Design Libraries</li>
            <li>Businessrelevanz</li>
            <li>Technische Machbarkeit</li>
            <li>Agile Arbeitsweise</li>
          </ul>
        </div>
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .design-stack {
          text-align: center;
          margin-top: 25px;
        }

        @from-width desktop {
          .design-stack {
            min-height: 0;
          }
        }

        h1 {
          font-size: ${theme.font.size.m};
        }

        h2 {
          font-size: ${theme.font.size.s};
          line-height: 2.8em;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          //font-variant: small-caps;
        }

        .list {
          list-style: none;
          font-size: ${theme.font.size.s};
        }

        .list li {
          margin-top: 0;
        }
      `}</style>
    </Fragment>
  );
};

export { MiyaProfileContent };
