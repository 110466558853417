import React from "react";
import PropTypes from "prop-types";
import { throttle } from "lodash";
import Profiles from "../Profiles";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.separator1 = React.createRef();
    this.separator2 = React.createRef();
    // this.separator3 = React.createRef();
  }

  componentDidMount() {
    try {
      const ScrollMagic = require("scrollmagic");
      this.scrollmagicController = new ScrollMagic.Controller();
      this.initProgressLine(ScrollMagic, ".trigger1", this.separator1);
      this.initProgressLine(ScrollMagic, ".trigger2", this.separator2);
      // this.initProgressLine(ScrollMagic, ".trigger3", this.separator3);
    } catch (e) {
      console.log(e);
    }
  }

  initProgressLine(ScrollMagic, triggerElementSelector, separatorRef) {
    new ScrollMagic.Scene({
      triggerElement: triggerElementSelector,
      triggerHook: "onEnter",
      duration: 700
    })
      .on(
        "progress",
        throttle(e => {
          const progress = e.progress.toFixed(2) * 100;
          separatorRef.current.style.width = progress + "%";
        }, 10)
      )
      .addTo(this.scrollmagicController);
  }

  componentWillUnmount() {
    if (this.scrollmagicController) {
      this.scrollmagicController.destroy(true);
    }
  }

  render() {
    const { theme } = this.props;

    return (
      <React.Fragment>
        <section className="services">
          <div className="container">
            <h1>Individuelle Software</h1>
            <p>
              Mit langjähriger Erfahrung und aufgebauter Kompetenz im ganzen Prozess der
              Softwareentwicklung von der Konzeption über Design und Entwicklung bis hin zum
              Betrieb, sind wir gut ausgerüstet,
              <strong> für Sie eine webbasierte Anwendung zu entwickeln.</strong>
            </p>
            <p>
              Für die Erstellung Ihres Endproduktes gehen wir nach höchsten Standards vor und legen
              großen Wert auf <strong> Qualität, gutes Aussehen und einfache Benutzbarkeit</strong>.
              <strong> Sie bekommen zukunftssichere Software</strong>, welche dank hoher
              Testabdeckung sicher und effizient weiterentwickelt werden kann, um stets ändernden
              Ansprüchen Ihres Geschäfts gerecht zu sein. Für Sie können wir auch gerne den
              vollständigen Betrieb übernehmen.
            </p>
          </div>
          <div className="separator-wrap trigger1">
            <div className="separator" ref={this.separator1} />
          </div>
          <div className="container">
            <h1>Beratung</h1>
            <p>
              Sie entwickeln bereits selber Software, benötigen aber Inspiration oder mehr
              Kapazität, um Ihre Ziele rechtzeitig zu erreichen? Wir{" "}
              <strong>
                unterstützen Sie gerne dabei und integrieren uns in Ihr bestehendes Team
              </strong>
              , um unser Knowhow und unsere Erfahrung zu teilen und gemeinsam schnellstmöglich an
              Ihre Ziele zu kommen.
            </p>
          </div>

          <div className="separator-wrap trigger2">
            <div className="separator" ref={this.separator2} />
          </div>

          {/*<div className="container">*/}
          {/*  <TechStack theme={theme} />*/}
          {/*</div>*/}

          {/*<div className="separator-wrap trigger3">*/}
          {/*  <div className="separator" ref={this.separator3} />*/}
          {/*</div>*/}

          <div className="container">
            <Profiles theme={theme} />
          </div>
        </section>

        {/*language=SCSS*/}
        <style jsx>
          {`
            .services {
              //background-color: #fff;
              color: #33404e;
              max-width: 800px;
              margin: 0 auto;
              //text-align: justify;
              hyphens: auto;
              padding-left: 2.5em;
              padding-right: 2.5em;

              :global(h1) {
                text-align: center;
                margin-bottom: 0.5em;
                font-size: ${theme.font.size.xl};
              }
            }

            section {
              display: block;
              padding-top: 7.42857143em;
              //padding-bottom: 7.42857143em;
            }

            p {
              margin-bottom: 1em;
              font-size: ${theme.font.size.m};
            }

            .container {
              margin-bottom: 5em;
            }

            .separator-wrap {
              margin: 0 auto;
              width: 80%;
            }

            .separator {
              border-top: 3px solid #69c2a4;
              width: 0;
              height: 100px;
              margin: 0 auto;
            }

            @between tablet desktop {
              .services {
                background-color: #fff;
                color: #33404e;
                max-width: 800px;
                margin: 0 auto;
                /*text-align: justify;*/
                hyphens: auto;
                padding-left: 5em;
                padding-right: 5em;
              }
            }

            @below tablet {
              text-align: justify;
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Home;
