import React from "react";
import PropTypes from "prop-types";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PublicIcon from "@material-ui/icons/Public";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import { DimaProfileContent } from "./DimaProfileContent";
import { MiyaProfileContent } from "./MiyaProfileContent";
import Grid from "@material-ui/core/Grid";

const styles = {
  card: {
    maxWidth: 300,
    margin: "0 auto",
    transition: "transform .4s",
    "&:hover": {
      transform: "scale(1.05)"
    },
    height: "100%"
  },

  photoFrame: {
    height: 200,
    width: 200,
    borderRadius: 180,
    overflow: "hidden",
    margin: "20px auto 0 auto",
    "z-index": "1",
    position: "relative"
  },

  content: {
    textAlign: "center"
  },
  actions: {
    justifyContent: "center",
    paddingBottom: 20
  },
  actionIcon: {
    marginRight: 5
  },

  actionButton: {
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#56a68c"
    }
  }
};

function ProfileCard(props) {
  const { classes, theme, profile } = props;

  const muiTheme = createMuiTheme({
    palette: {
      primary: {
        main: theme.color.brand.primary
      }
    },
    typography: {
      useNextVariants: true
    }
  });

  function createTrackedOnclick(trackEventLabel) {
    return function onClick(e) {
      if (window.gtag) {
        window.gtag("event", "click", {
          event_category: "outbound",
          event_label: trackEventLabel,
          transport_type: "beacon"
        });
      }

      return false;
    };
  }

  return (
    <StaticQuery
      query={graphql`
        query AvatarsQuery {
          avatars: allFile(filter: { relativePath: { regex: "/profiles/.*/" } }) {
            edges {
              node {
                base
                childImageSharp {
                  id
                  ... on ImageSharp {
                    fluid(maxWidth: 1024, maxHeight: 1024) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ avatars: { edges: avatars } }) => {
        const {
          node: {
            childImageSharp: { fluid: avatarImage }
          }
        } = avatars.find(avatar => {
          return avatar.node.base === profile.avatar;
        });
        return (
          <Card elevation={3} className={classes.card}>
            <Grid container direction={"column"} style={{ height: "100%" }}>
              <Grid item>
                <div className={classes.photoFrame}>
                  <Img fluid={avatarImage} title={`${profile.name} photo`} />
                </div>
              </Grid>
              <Grid
                item
                style={{
                  flexGrow: 1
                }}
              >
                <Grid
                  container
                  direction={"column"}
                  justify={"space-between"}
                  style={{ height: "100%" }}
                >
                  <Grid item>
                    <CardContent className={classes.content}>
                      <Typography align={"center"} gutterBottom variant="h5" component="h2">
                        {profile.name}
                      </Typography>
                      <Typography component="p">{profile.role}</Typography>
                      {profile.infoComponent === "dima" && <DimaProfileContent theme={theme} />}
                      {profile.infoComponent === "miya" && <MiyaProfileContent theme={theme} />}
                    </CardContent>
                  </Grid>
                  <Grid item>
                    <MuiThemeProvider theme={muiTheme}>
                      <CardActions className={classes.actions}>
                        {profile.cv && (
                          <Button
                            // variant="outlined"
                            color="primary"
                            href={profile.cv}
                            target={"profil_tab"}
                            onClick={createTrackedOnclick(profile.cv)}
                            className={classes.actionButton}
                          >
                            <CloudDownloadIcon className={classes.actionIcon} />
                            Profil
                          </Button>
                        )}
                        {profile.xing && (
                          <Button
                            variant="contained"
                            color="primary"
                            href={profile.xing}
                            target={"_blank"}
                            onClick={createTrackedOnclick(profile.xing)}
                            className={classes.actionButton}
                          >
                            <PublicIcon className={classes.actionIcon} />
                            XING
                          </Button>
                        )}
                      </CardActions>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        );
      }}
    />
  );
}

ProfileCard.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    cv: PropTypes.string,
    xing: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(ProfileCard);
