import React, { Fragment } from "react";

const DimaProfileContent = ({ theme }) => {
  return (
    <Fragment>
      <div>
        {/*<h1>Technologischer Stack</h1>*/}
        <div className="tech-stack">
          <h2>Backend</h2>
          <ul className="list">
            <li>Spring Boot | Spring Cloud</li>
            <li>Kotlin | Java</li>
            <li>Microservices</li>
            <li>REST API Design und Entwicklung</li>
            <li>MariaDB | PostgreSQL | MongoDB</li>
            <li>GraphQL</li>
            <li>Docker</li>
          </ul>
        </div>
        <div className="tech-stack">
          <h2>Frontend</h2>
          <ul className="list">
            <li>HTML5 | CSS | JavaScript | ES6</li>
            <li>React | Redux | Angular</li>
          </ul>
        </div>
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .tech-stack {
          text-align: center;
          margin-top: 25px;
        }

        h1 {
          font-size: ${theme.font.size.m};
        }

        h2 {
          font-size: ${theme.font.size.s};
          line-height: 2.8em;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          //font-variant: small-caps;
        }

        .list {
          list-style: none;
          font-size: ${theme.font.size.s};
        }

        .list li {
          margin-top: 0;
        }
      `}</style>
    </Fragment>
  );
};

export { DimaProfileContent };
